<template>
    <div class="login">
      <v-app>
        <v-main>
          <div class="limiter">
            <div class="container-login100">
              <div class="wrap-login100">
  
                <div class="login100-more color-fond">
                  <div class="log-corp-exata">
                      <center>
                      <v-img class="subheading"
                        lazy-src="../../assets/img/logoexata.png"
                        max-height="600"
                        max-width="600"
                        src="../../assets/img/logoexata.png"  
                      >
                      </v-img>
                      <div class="flex items-center">
                        <table>
                          <tr>
                            <td>
                              <h2 class="text-white font-bold text-4xl mt-8">
                                <strong> Nossa fonte de energia é o sol</strong></h2>
                            </td>
                            <td>
                             <v-img class="subheading"
                          lazy-src="../../assets/img/telinha.gif"
                          max-height="90"
                          max-width="90"
                          src="../../assets/img/telinha.gif"
                        >
                        </v-img>
                            </td>
                          </tr>
                        </table>
                    </div>
                    </center>
                  </div>
                </div>
                <v-form @submit.prevent="submitHandler" ref="form" class="login100-form" style="margin-top: -5%">
                  <span class="login100-form-title p-b-43">
                    <center>
                    <v-img
                    class="subheading"
                      lazy-src="../../assets/img/EXATA_ENGENHA.png"
                      max-height="200"
                      max-width="200"
                      src="../../assets/img/EXATA_ENGENHA.png"
                    ></v-img>
                    <h1 style="font-weight: 600;">Meu Gestor Solar</h1>
                  </center>
                  </span>
                  <div class="text-messag-momore">
                    Para altera a senha é  necessário inserir seu código de confirmação.
                  </div>
  
                  
                  <div>
                    <v-otp-input
                    v-model="dataFrom.key_access"
                    :rules="key_accessRules"
                    type="text"
                    length="5"
                    color="#00796b"
                    placeholder="#"
                    :timeout="2000"
                    required
                  ></v-otp-input>
                  </div>
                  <div class="flex-sb-m w-full p-t-3 p-b-32">
                    <div>
                      <a href="#" class="txt1">
                        <router-link style="font-weight: 900;" to="/reset-password">
                          <div class="color-event text-decoration">
                            Esqueçeu a senha?
                          </div>
                        </router-link>
                      </a>
                    </div>
                  </div>
            
                  <div class="container-login100-form-btn">
                      <v-card-actions class="justify-center">
                        <v-btn x-large style="width: 200px;" :loading="loading" type="submit" color="#00796b" >
                          <span class="white--text px-8">
                            <strong>Confirma</strong>
                          </span>
                        </v-btn>
                      </v-card-actions>
                  </div>
  
                  <router-link style="font-weight: 900;" to="/login">
                        <div class="color-event">Já está registrado?  
                          <span style="text-decoration: underline;">Login</span></div>
                  </router-link>
                </v-form>
              </div>
            </div>
          </div>
          <!--<v-snackbar color="#00796b" v-model="snackbar">
           MSM => 
          </v-snackbar>-->
        </v-main>
      </v-app>
    </div>
  </template>
  
  <script>
  import ResetPasswordCode from '@/services/Reset-password/index'
  // @ is an alias to /src
  export default {
  name: 'reset-password-code',
  data () {
    return {
      loading:false,
      snackbar:false,
      message: '',
      dataFrom:{
        key_access: '',
      },
      key_accessRules: [
      v => !!v || 'Password é obrigatório',
      v => (v && v.length >= 5) || 'Password deve ter 8 caracteres ou mais!']
    }
  },
  mounted(){},
  methods:{
    submitHandler(){
      if(this.$refs.form.validate()){
        this.loading = true
        setTimeout(()=> {
          this.loading = false
          this.snackbar = true
          //console.log('CODE -> ',this.dataFrom)
          ResetPasswordCode.validatedCodeSend(this.dataFrom);
          //console.log(this.$router.push("/restore-password"))
        }, 1000)
      }
    }
  },
  components:{}
  }
  </script>
  <style>
  .background{
    background-color: #00796b !important;
    height: 300px;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    background-size: cover;
    background: linear-gradient(-45deg, #55A38B, #00796b, #80cbc4);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  }
  
  @keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
  }
  .input-color{
  color: "#00796b"
  }
  .border-sobra{
  -webkit-box-shadow: 9px 7px 5px rgba(50, 50, 50, 0.77);
    -moz-box-shadow:    9px 7px 5px rgba(50, 50, 50, 0.77);
    box-shadow:         9px 7px 5px rgba(50, 50, 50, 0.77);
  }
  .color-event{
  color: #4D4D4C;
  }
  
  .color-event:hover{
  color: #000;
  }
  </style>