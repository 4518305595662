import Api from '@/services/Api/api'
import router from '@/router'
import MessageResetPassword from '@/components/alert/reset-password'

export default {

    apiRouter:()=>{
          return Api().get('/msm')
    },
  
    emailCodeSend:(email) => {
        return Api().post('/auth/company/reset-password-inf', email).then(response => {
        //console.log(response.status, response.data) // => resulte de informt true
        var message = { status: response.status, msm:'Código de confirmação enviado com sucesso.'}
        MessageResetPassword.messageSendEmail(message)
      }).catch(e => {
        //console.log(e.response.status, e.response.data)
        var message = { 
            status: e.response.status, 
            msm: 'Esse e-mail é inexistente na nossa plataforma.'
        }
         MessageResetPassword.messageSendEmail(message)     
      })
    },

    validatedCodeSend:(code) => {
        return Api().post('/auth/company/reset-password-code-status', code).then(response => {
            if(response.status === 200){
                //console.log(response.status, response.data) // => resulte de informt true
                var message = { status: response.status, msm:'Código autenticado com sucesso.'}
                MessageResetPassword.messageSendEmail(message)
                router.push({path: '/restore-password', query:{email: response.data.results.email}})
            }
        }).catch(e => {
            //console.log(e.response.data.message+" "+e.response.data.errors)
            //console.log(e.response.status, e.response.data)
            var message = { 
                status: e.response.status, 
                msm: 'Código Negativado.'
            }
            MessageResetPassword.messageSendEmail(message)
           
        })
    },

    validatedUpSend:(updPassword) => {
        return Api().post('/auth/company/reset-password-up', updPassword).then(response => {
            //console.log(response.status, response.data) // => resulte de informt true
            var message = { status: response.status, msm:'Sua senha foi alterada com sucesso.'}
            MessageResetPassword.messageSendEmail(message)
            router.push({path: '/'})

        }).catch(e => {
            //console.log(e.response.status, e.response.data)
            //const message = (e.response.data.message+" "+e.response.data.errors)
            var message = { 
                status: e.response.status, 
                msm: "Sua, senha já foi alterada. Se quiser alterar novamente deve clicar em 'Esqueceu a senha?' na tela de login."
            }
            MessageResetPassword.messageSendEmail(message)
        })
    },
  
}