<template>
  <v-container fluid 
  style="
    /* margin: 0; */
    margin-top: 05%;
    width: 30%;
  ">
    <v-row justify="center">
      <v-expansion-panels default>
        <v-expansion-panel
          v-for="(message, i) in messages"
          :key="i"
          hide-actions
        >
          <v-expansion-panel-header style="height: 1px;">
            <v-row 
              align="center"
              class="spacer"
        
            >
              <v-col
                cols="4"
                sm="2"
                md="1"
              >
                <v-avatar size="36px"  style="left: -15px !important;">
                  <img
                    v-if="message.avatar"
                    alt="Avatar"
                    src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"
                  >
                  <v-icon
                    v-else
                    :color="message.color"
                    v-text="message.icon"
                  ></v-icon>
                </v-avatar>
              </v-col>

              <v-col class="hidden-xs-only" style="align-self: center;" >
                <center>
                  <strong v-html="message.name"></strong>
                </center>
          
                <!--<span sm="5" md="3"
                  v-if="message.total"
                  class="grey--text"
                >
                  &nbsp;({{ message.total }})
                </span>-->
              </v-col>

              <!--<v-col
                class="text-no-wrap"
                cols="5"
                sm="3"
              >
                <v-chip
                  v-if="message.new"
                  :color="`${message.color} lighten-4`"
                  class="ml-0 mr-2 black--text"
                  label
                  small
                >
                  {{ message.new }} new
                </v-chip>
                <strong v-html="message.title"></strong>
              </v-col>

              <v-col
                v-if="message.excerpt"
                class="grey--text text-truncate hidden-sm-and-down"
              >
                &mdash;
                {{ message.excerpt }}
              </v-col>-->
            </v-row>
          </v-expansion-panel-header>

        <v-expansion-panel-content>
            <v-divider></v-divider>
            <v-card-text v-text="lorem"></v-card-text>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      messages: [
        {
          avatar: 'https://avatars0.githubusercontent.com/u/9064066?v=4&s=460',
          name: 'EXATA',
          title: 'EXATA',
          excerpt: 'EXATA',
          items: [
              { title: "Management", icon: "mdi-account-multiple-outline", active: false, to: "/admin/management"},
              { title: "Settings", icon: "mdi-cog-outline", active: false, to: "/admin/setting"}
            ] },
      ],
      lorem: 'constituam cum.',
    }),
  }
</script>