//import Api from '@/services/Api/api'
import Vue from 'vue';

export default {

    messageRegister:(message) => {
        if(message.status != 200){
            return Vue.swal({ icon: 'error', title: 'Oops...', text: message.msm, confirmButtonColor: '#FF5252'})
        }
        Vue.swal({ icon: 'success'/*,title: `${message.msm}`*/, text: `${message.msm}`,
        confirmButtonColor: '#00796b'})
    },

    /*Formatação*/

    messageEmailFormt:(value) => {
        if(!value){
            return value = ''
        }
       return value.toString().replace('O campo','Esse')
    },
    messageCnpjFormt:(value) => {
        if(!value){
            return value = ''
        }

        return value.toString().replace('O campo cpf','Esse')
    }

}