<template>
    <v-app-bar app color="deep-purple accent-4" dark>
      
      <v-spacer></v-spacer> <Search/></v-spacer>
      
      <v-btn id="button-icons" icon>
        <v-badge avatar overlap class="triangle">
          <template v-slot:badge>
            <div class="my-badge">1</div>
          </template>
          <v-icon>mdi-bell-outline</v-icon>
        </v-badge>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-calendar-text</v-icon>
      </v-btn>
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <AvatarUser/>
          <!--<v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>-->
        </template>

        <v-list>
          <v-list-item v-for="n in 4" :key="n" @click="() => {}">
            Option {{ n }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
</template>
<script>
  import AvatarUser from '@/components/avatar/avatar_user.vue';
  import Search from '@/components/bar/Search.vue';
  export default {
    name: "Topbar",
    data() {
      return {}
    },
    components:{
      AvatarUser,
      Search
    }
  };
  </script>
  
<style>
#topbar{
  /*background-color: #a2a2a2;*/
  position: relative;
  background-color: #fff;
  margin-top: 0;
  border-radius: 0px;
  height: 100px;
  width: 100%;
}
#icons{
 color: #55A38B;
}
#inspire {
  margin-top: 1px;
  width: 100%;
}

.triangle .v-badge__badge {
  border-radius: 0;
  clip-path:  circle(40%);
  
  height: 30px;
  width: 30px;
}

.my-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0.10em;
  font-size: 14px;
  height: 100%;
  color: #fff;
  background: #00796b;
}
#button-icons{
  margin: 0;
  margin-top: 10px;
  background-color: #272727;
  border-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-icons:hover {
    background: #00796b;
}

</style>
  