<template>
  <div>
        <v-text-field
            v-model="celulcar"
            color="#00796b"
            label="Celulcar"
            type="phone"
            v-mask="mask"
            placeholder="Celulcar"
            prepend-inner-icon="mdi-phone"
            required
        />
    </div>
</template>

<script>
export default {
    data() {
    return {
      mask: '(##) #####-####',
      celulcar: ''
    };
  }
}
</script>

<style>

</style>