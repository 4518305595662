<template>
  <div class="text-center">
    <v-autocomplete class="pa-0"
    style="
      align-self: center; 
      top: 10px; 
      width: 100%;
      color: #00796b"
      dense 
      d-flex
      height
      prepend-icon="mdi-magnify">
  </v-autocomplete>
  </div>
  </template>
  <script>
    export default {
      name: 'Search',
      data: () => ({
       }),
    }
  </script>

<style>

</style>