<template>
    <div>
        <v-text-field
            v-model="telefone"
            color="#00796b"
            label="Telefone"
            type="text"
            v-mask="mask"
            placeholder="Telefone"
            prepend-inner-icon="mdi-phone-classic"
            required
        />
    </div>
</template>

<script>
export default {
    data() {
    return {
      mask: '#### ### ####',
      telefone: ''
    };
  }
}
</script>

<style>

</style>