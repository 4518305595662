import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import ResetPassword from '../views/reset-password/ResetPassword.vue'
import ResetPasswordCode from '../views/reset-password/ResetPasswordCode.vue'
import ResetPasswordUp from '../views/reset-password/ResetPasswordUp.vue'
import Dashboard from '../views/dashboard/Dashboard.vue'
import VueMask from 'v-mask'

import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd'
import { uniLayerGroupMonochrome, uniCarWash } from 'vue-unicons/dist/icons'
Unicon.add([uniLayerGroupMonochrome, uniCarWash])

Vue.use(VueRouter, VueMask, Unicon)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword
  },
  {
    path: '/reset-password-code',
    name: 'reset-password-code',
    component: ResetPasswordCode
  },
  {
    path: '/restore-password',
    name: 'restore-password',
    component: ResetPasswordUp
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
