<template>
    <div id="inspire">
      <Sidebar :drawer="drawer"/>
  
      <Topbar @drawerEvent="drawer = !drawer"/>
    
      <v-main id="main-style">
        <v-container class="py-8 px-6" fluid>
          <Cards/>
         
          <Datatable class="card-box-container-table"/>
          <router-view></router-view>
        </v-container>
        <v-btn @click="StyleSideBar()">AQUI</v-btn>
      </v-main>
    </div>
  </template>
  
  <script>
  import Sidebar from "@/components/bar/Sidebar";
  import Topbar from "@/components/bar/Topbar";
  import Cards from "@/components/dashboard/card.vue";
  import Datatable from "@/components/dashboard/datatable.vue";
  import router from "@/router";
  export default {
    name: "Dashboard",
    data() { 
        return {
          cards: ["Today", "Yesterday"],
          drawer: null,
          sidebar: router.history.current.query.sidebar,
          var:''
        }
        
    },
    components: { Topbar, Sidebar, Cards, Datatable},
    props: {
      //! Menu settings
      isMenuOpen: {
        type: Boolean,
        default: true,
      },
      menuTitle: {
        type: String,
        default: 'EXATA',
      },
      menuLogo: {
        type: String,
        default: '',
      },
      menuIcon: {
        type: String,
        default: 'bxl-c-plus-plus',
      },
      isPaddingLeft: {
        type: Boolean,
        default: true,
      },
       menuOpenedPaddingLeftBody: {
        type: String,
        default: '250px'
      },
      menuClosedPaddingLeftBody: {
        type: String,
        default: '78px'
      },
      //! Menu items
      menuItems: {
        type: Array,
      },
      //! Search
      isSearch: {
        type: Boolean,
        default: true,
      },
      searchPlaceholder: {
        type: String,
        default: 'Search...',
      },
      searchTooltip: {
        type: String,
        default: 'Search',
      },
      //! Profile detailes
      profileImg: {
        type: String,
        default: require('../../assets/logo.png'),
      },
      profileName: {
        type: String,
        default: '',
      },
      profileRole: {
        type: String,
        default: 'Frontend vue developer',
      },
      isExitButton: {
        type: Boolean,
        default: true,
      },
      isLoggedIn: {
        type: Boolean,
        default: true,
      },
      //! Styles
      bgColor: {
        type: String,
        default: '#fff',
      },
      secondaryColor: {
        type: String,
        default: '#1d1b31',
      },
      homeSectionColor: {
        type: String,
        default: '#e4e9f7',
      },
      logoTitleColor: {
        type: String,
        default: '#fff',
      },
      iconsColor: {
        type: String,
        default: '#fff',
      },
      itemsTooltipColor: {
        type: String,
        default: '#e4e9f7',
      },
      searchInputTextColor: {
        type: String,
        default: '#fff',
      },
      menuItemsHoverColor: {
        type: String,
        default: '#55A38B',
      },
      menuTextsHoverColor: {
        type: String,
        default: '#55A38B',
      },
      menuItemsTextColor: {
        type: String,
        default: '#fff',
      },
      menuFooterTextColor: {
        type: String,
        default: '#fff',
      },
      sidemeuMin: {
        type: String,
        default: '50%',
      },
      sidemeuMax: {
        type: String,
        default: '700px',
      }
    },
    methods:{
      StyleSideBar(){
        console.log(router.history.current.query.sidebar)
        if(router.history.current.query.sidebar == '250px'){
            this.var = this.sidemeuMin;
            alert(this.sidemeuMin)
        }
      }
    },
    computed: {
      cssVars() {
        return {
          // '--padding-left-body': this.isOpened ? this.menuOpenedPaddingLeftBody : this.menuClosedPaddingLeftBody,
          '--bg-color': this.bgColor,
          '--secondary-color': this.secondaryColor,
          '--home-section-color': this.homeSectionColor,
          '--logo-title-color': this.logoTitleColor,
          '--icons-color': this.iconsColor,
          '--items-tooltip-color': this.itemsTooltipColor,
          '--menu-items-hover-color': this.menuItemsHoverColor,
          '--menu-text-hover-color': this.menuTextHoverColor,
          '--menu-items-text-color': this.menuItemsTextColor,
          '--menu-footer-text-color': this.menuFooterTextColor,
          '--sidemeuMin': this.sidemeuMin,
        }
      },
    }
}
  </script>
  <style scoped>
    #main-style{
      position: absolute; 
      background-color: #F0F3F3; 
      margin-top: 2%;
      margin: 0;
      margin-left: -5%;
      width: 100%;
      height: 100%;
    }
    .card-box-container-table{
        position: relative;
        margin-top: 10%;
        color: var(--bg-color);
        width:  var(--sidemeuMin);
        /*width: calc(100% - var(--sidemeuMin));*/
        margin-left: 9%;
    }
</style>
