//import Api from '@/services/Api/api'
import Vue from 'vue';

export default {

    messageLogin:(message) => {
        if(message.status != 200){
            return Vue.swal({ icon: 'error', title: 'Oops...', text: message.msm, confirmButtonColor: '#FF5252'})
        }
        Vue.swal({ icon: 'success'/*,title: `${message.msm}`*/, text: `${message.msm}`, 
        confirmButtonColor: '#00796b'})
    }

}