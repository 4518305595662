<template>
    <v-container>
      <v-text-field @click="showAlert">Hello world</v-text-field>
    </v-container>
</template>

<script>
export default {
  methods: {
    showAlert() {
      // Use sweetalert2
      this.$swal('Hello Vue world!!!');
    },
  },
};
</script>