import Api from '@/services/Api/api'
import MessageLogin from '@/components/alert/login/index'
import router from '@/router'

//import Vue from 'vue';

export default {
  login:(login) => {
		return Api().post('/auth/company/login', login).then(response => {
      //console.log(response.status, response.data) // => resulte de informt true
      var message = { status: response.status, msm:'Login efetuado com sucesso.'}
      MessageLogin.messageLogin(message)
      router.push({path: '/dashboard', query:{email: response.data.results.email}})

    }).catch(e => {
      //console.log(e.response.status+' '+e.response.data.message) // => resulte de informt false
      var message = { status: e.response.status, msm: e.response.data.message}
      MessageLogin.messageLogin(message)
    })
  }
}