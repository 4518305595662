<template>
    <div>
        <v-text-field
            v-model="password"
            :rules="passwordRules"
            color="#00796b"
            label="Password"
            placeholder="Password"
            prepend-inner-icon="mdi-key"
            :append-icon="passwordShow ? 'mdi-eye' :'mdi-eye-off'"
            :type="passwordShow?'text':'password'"
            @click:append="passwordShow = !passwordShow"
            required
        />
      </div>
  </template>
  
  <script>
  export default {
      data() {
      return {
        passwordShow:false,
        password: '',
        passwordRules: [
        v => !!v || 'Password é obrigatório',
        v => (v && v.length >= 8) || 'Password deve ter 8 caracteres ou mais!',
        ],
      };
    }
  }
  </script>
  
  <style>
  
  </style>