<template>
    <v-container class="card-box-container" grid-list-md text-xs-center>
        <!--<v-row>
            <v-col>
                <v-card class="mx-auto" max-width="344">
                    <v-card id="card-box-min">
                        <v-icon id="icons-box-min">
                            mdi-account-circle-outline
                        </v-icon>
                    </v-card>

                    <v-card-title>
                    Top western road trips
                    </v-card-title>

                    <v-card-subtitle>
                    1,000 miles of wonder
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>-->
  
      <v-layout row wrap id="layout">

     
        <v-flex  v-for="i in 4" :key="`4${i}`" xs3>
            <v-card  class="mx-auto"  id="card">

                <v-card id="card-box-min">
                    <v-icon id="icons-box-min">
                        mdi-account-circle-outline
                    </v-icon>
                </v-card>

                <div id="card-title">
                    <v-card-subtitle id="sub-card-text-01">
                        Bookings
                    </v-card-subtitle>

                    <v-card-subtitle id="sub-card-text-02">
                        +245
                    </v-card-subtitle>
                </div>
                
                <v-divider ></v-divider>

                <v-list-item id="icons-sub-card">
                    <v-list-item-content>
                    <v-list-item-title>
                        <v-icon style="color:#4CAF50;">
                            mdi-arrow-up 
                        </v-icon>
                        <span style="color:#4CAF50;">
                            %65
                        </span> 
                        Main Street 
                    </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
        </v-flex>
      </v-layout>
      
    </v-container>
</template>


<script>

export default {
    name:'cardDashboard',
    data() {
        return {

        }
    },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
    .card-box-container{
        position: relative;
        width: 100%;
    }
    #card-box-min{
        display: block;
        position: absolute;
        padding: 10px;
        background-color: #4CAF50;
        width: 35%; 
        height: 79px;
        margin-left: 10px;
        margin-top: -10px;
        -webkit-box-shadow: 1px 1px 5px rgba(50, 50, 50, 0.77);
		-moz-box-shadow:    1px 1px 5px rgba(50, 50, 50, 0.77);
		box-shadow:         1px 1px 5px rgba(50, 50, 50, 0.77);
    }
    #icons-box-min{
        top: 5px;
        color: #fff;
        font-size: 50px;
        align-self: center;
        text-align: center;
        text-transform: uppercase;
       
    }
    #card{
        z-index: 2;
        max-width: 100%;
        border-collapse: collapse; 
        list-style-type: none;
        padding: 0;
        margin: 0;
        height: 120px;
        -webkit-box-shadow: 1px 1px 5px rgba(50, 50, 50, 0.77);
		-moz-box-shadow:    1px 1px 5px rgba(50, 50, 50, 0.77);
		box-shadow:         1px 1px 5px rgba(50, 50, 50, 0.77);
    }
    #card-title{
        position: relative;
        right: auto;
        text-align: right;
    }
    #sub-card-text-01{
        color: #B7B7B7;
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
        font-family: 'Poppins', sans-serif;

    }
    #sub-card-text-02{
        color: #1C1C1C;
        margin-top: -30px !important;
        font-size: 25px;
        text-transform: uppercase;
        font-family: 'Poppins', sans-serif;

    }
    
    #icons-sub-card{
        margin-top: -27px;
        left: -20% !important;
        color: #83727B;
    }
    
    #layout{
        position: absolute;
        padding: 12px;
       
        width: calc(100% - 100px);
        align-self: center;
        margin-left: 20px;
    }
    body{
        background-color: #F0F3F3;
    }
    
</style>