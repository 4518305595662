<template>
    <div>
        <v-text-field
            v-model="cpf_cnpj"
            v-mask="mask"
            :rules="CnpjRules"
            color="#00796b"
            label="CNPJ"
            type="text"
            placeholder="cnpj"
            hide-details="auto"
            prepend-inner-icon="mdi-account"
            required
            /></br>
    </div>
</template>

  <script>
    import validator from 'cpf-cnpj-validator';
    const Joi = require('joi').extend(validator)
    const cnpjSchema = Joi.document().cnpj();
    //const objeto = (cnpjSchema.validate('383213108000107'))
    //console.log(objeto)
    //console.log(objeto['error'])
    //console.log( (cnpjSchema.validate('383213108000107'))['error'])
    export default {
        
      data: () => ({
        mask: '##.###.###/####-##',
        cpf_cnpj: '',
        CnpjRules: [
        v => !!v || 'Cnpj é obrigatório',
        v =>  (v && v.replace(/[^\d]+/g,'').length >= 14) || 'Cnpj deve ser preenchido por completo',
        v =>   (cnpjSchema.validate(v.replace(/[^\d]+/g,''))['error']) != 'ValidationError: CNPJ inválido' || 'Cnpj inválido'  
       ],
      })
    }
  </script>