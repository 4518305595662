<template>
  <div>
    <v-text-field
        v-model="name_empresa"
        :rules="nameEmpresaRules"
        :counter="100"
        color="#00796b"
        label="Nome da empresa"
        type="text"
        placeholder="Nome da empresa"
        prepend-inner-icon="mdi-account-supervisor-circle-outline"
        required
        />
    </div>
</template>

<script>
export default {
    data: () => ({
    name_empresa: '',
    props: {
      empresa: this.name_empresa,
    },
    nameEmpresaRules: [
        v => !!v || 'O nome é obrigatório',
        v => v.length <= 100 || 'O nome deve ter menos de 100 caracteres',
      ]
  })
}
</script>

<style>

</style>