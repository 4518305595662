<template>
    <div class="login">
      <v-app>
        <v-main>
          <div class="limiter">
            <div class="container-login100">
              <div class="wrap-login100">

                <div class="login100-more color-fond">
                  <div class="log-corp-exata">
                      <center>
                      <v-img class="subheading"
                        lazy-src="../../assets/img/logoexata.png"
                        max-height="600"
                        max-width="600"
                        src="../../assets/img/logoexata.png"  
                      >
                      </v-img>
                      <div class="flex items-center">
                        <table>
                          <tr>
                            <td>
                              <h2 class="text-white font-bold text-4xl mt-8">
                                <strong> Nossa fonte de energia é o sol</strong></h2>
                            </td>
                            <td>
                              <v-img class="subheading"
                                lazy-src="../../assets/img/telinha.gif"
                                max-height="90"
                                max-width="90"
                                src="../../assets/img/telinha.gif"
                                >
                                </v-img>
                            </td>        
                          </tr>
                        </table>
                    </div>
                    </center>
                  </div>
                </div>
                <v-form @submit.prevent="submitHandler" ref="form" class="login100-form" style="margin-top: -5%">
                  <span class="login100-form-title p-b-43">
                    <center>
                    <v-img
                    class="subheading"
                      lazy-src="../../assets/img/EXATA_ENGENHA.png"
                      max-height="200"
                      max-width="200"
                      src="../../assets/img/EXATA_ENGENHA.png"
                    ></v-img>
                    <h1 style="font-weight: 600;">Meu Gestor Solar</h1>
                  </center>
                  </span>
                  <div style="margin-left: -70%; font-weight: 600;">
                    Novo Password
                  </div>
                  <div>
                    <v-text-field
                        v-model="dataFrom.password"
                        :rules="passwordRules"
                        color="#00796b"
                        label="Novo Password"
                        placeholder="Novo Password"
                        prepend-inner-icon="mdi-key"
                        :append-icon="passwordShow ? 'mdi-eye' :'mdi-eye-off'"
                        :type="passwordShow?'text':'password'"
                        @click:append="passwordShow = !passwordShow"
                        required
                      />
                  </div>
            
                  <div class="container-login100-form-btn">
                      <v-card-actions class="justify-center">
                        <v-btn x-large style="width: 200px;" :loading="loading" type="submit" color="#00796b" >
                          <span class="white--text px-8">
                            <strong>Confirma</strong>
                          </span>
                        </v-btn>
                      </v-card-actions>
                  </div>

                  <div style="font-weight: 900;"></div>
                  <router-link style="font-weight: 900;" to="/login">
                        <div class="color-event">Já tem uma conta?  
                          <span style="text-decoration: underline;">Faça seu Login</span></div>
                  </router-link>
                </v-form>

              </div>
            </div>
          </div>
          <v-alert color="#00796b" v-model="snackbar">
           {{ message }}
          </v-alert>
        </v-main>
      </v-app>
    </div>
  </template>

<script>
import router from '@/router';
import ResetPasswordUpApi from '@/services/Reset-password/index';

// @ is an alias to /src
export default {
  name: 'reset-password-up',
  data () {
    return {
      loading:false,
      snackbar:false,
      passwordShow:false,
      email: router.history.current.query.email,
      message: '',
      dataFrom:{
        password: '',
      },
      passwordRules: [
      v => !!v || 'O Password é obrigatório',
      v => (v && v.length >= 8) || 'Password deve ter 8 caracteres ou mais!']
    }
  },
  mounted(){},
  methods:{
    submitHandler(){
      if(this.$refs.form.validate()){
        this.loading = true
        setTimeout(()=> {
          this.loading = false
          this.snackbar = true

          var json = {
              email: this.email,
              password: this.dataFrom.password
          }

          ResetPasswordUpApi.validatedUpSend(json);
        }, 3000)
      }
    }
  },
  components:{}
  }
</script>
<style>
.background{
    background-color: #00796b !important;
    height: 300px;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    background-size: cover;
    background: linear-gradient(-45deg, #55A38B, #00796b, #80cbc4);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.input-color{
  color: "#00796b"
}
.border-sobra{
  -webkit-box-shadow: 9px 7px 5px rgba(50, 50, 50, 0.77);
		-moz-box-shadow:    9px 7px 5px rgba(50, 50, 50, 0.77);
		box-shadow:         9px 7px 5px rgba(50, 50, 50, 0.77);
}
.color-event{
  color: #4D4D4C;
}

.color-event:hover{
  color: #000;
}
</style>