<template>
    <div>
        <v-text-field
            v-model="email"
            :rules="emailRules"
            color="#00796b"
            label="Email"
            type="email"
            placeholder="Email"
            prepend-inner-icon="mdi-mailbox"
            required
        />
      </div>
  </template>
  
  <script>
  export default {
      data() {
      return {
        celulcar: '',
        emailRules: [
        v => !!v || 'O e-mail é obrigatório',
        v => /.+@.+\..+/.test(v) || 'E-mail deve ser válido',
        ],
      };
    }
  }
  </script>
  
  <style>
  
  </style>