import Api from '@/services/Api/api'
import MessageRegister from '@/components/alert/register/index'

//import Vue from 'vue';

export default {

  apiRouter:()=>{
		return Api().get('/msm')
  },

  register:(register) => {
		return Api().post('/auth/company/register', register).then(response => {
        //console.log(response.status, response.data) // => resulte de informt true
        var message = { status: response.status, msm:'Registro efetuado com sucesso.'}
        MessageRegister.messageRegister(message)
    }).catch(e => {
      //console.log(e.response.status+' '+e.response.data.message) // => resulte de informt false
      // console.log(e.response.status, e.response.data)  //console.log((e.response.data.errors))
      var message = { 
        status: e.response.status, 
        msm: MessageRegister.messageCnpjFormt(e.response.data.errors.cpf_cnpj)+' '
            +MessageRegister.messageEmailFormt(e.response.data.errors.email)}
        
            MessageRegister.messageRegister(message)     
    })
  },
  
  /*Format*/
  formatTrim:(value) =>{
    return value.replace(/\s+/g, '')
  },

  formatCnpj:(value)=>{
    return value.replace(/[^\d]+/g,'')
  },
  formatCelTel:(value)=>{
    return value.replace(/[^\d]+/g,'')
  }

}