<template>
    <div class="register">
      <v-app>
        <v-main>
          <div class="limiter">
            <div class="container-login100">
              <div class="wrap-login100">

                <div class="login100-more color-fond">
                  <div class="log-corp-exata">
                    <center>
                      <v-img class="subheading"
                        lazy-src="../assets/img/logoexata.png"
                        max-height="600"
                        max-width="600"
                        src="../assets/img/logoexata.png"  
                      >
                      </v-img>
                      <div class="flex items-center">
                        <table>
                          <tr>
                            <td>
                              <h2 class="text-white font-bold text-4xl mt-8">
                                <strong> Nossa fonte de energia é o sol</strong></h2>
                            </td>
                            <td>
                              <v-img class="subheading"
                          lazy-src="../assets/img/telinha.gif"
                          max-height="90"
                          max-width="90"
                          src="../assets/img/telinha.gif"
                        >
                        </v-img>
                            </td>
                          </tr>
                        </table>
                    </div>
                    </center>
                  </div>
                </div>
                <v-form @submit.prevent="submitHandler" ref="form" class="login100-form" style="margin-top: -10%">
                  <span class="login100-form-title p-b-43">
                    <center>
                    <v-img
                    class="subheading"
                      lazy-src="../assets/img/EXATA_ENGENHA.png"
                      max-height="200"
                      max-width="200"
                      src="../assets/img/EXATA_ENGENHA.png"
                    ></v-img>
                    <h1 style="font-weight: 600;">Meu Gestor Solar</h1>
                  </center>
                  </span>
                  <div style="margin-left: -90%; font-weight: 600;">
                    Cadastro
                  </div>
               
                  <div>
                    <v-text-field
                      v-model="dataFrom.name"
                      :rules="nameEmpresaRules"
                      :counter="100"
                      color="#00796b"
                      label="Nome da empresa"
                      type="text"
                      placeholder="Nome da empresa"
                      prepend-inner-icon="mdi-account-supervisor-circle-outline"
                      required
                      />
                  </div>

                  <div>
                    <v-text-field
                      v-model="dataFrom.name_reference"
                      :rules="nameEmpresaRules"
                      :counter="100"
                      color="#00796b"
                      label="Nome fantasia"
                      type="text"
                      placeholder="Nome fantasia"
                      prepend-inner-icon="mdi-account-supervisor-circle-outline"
                      required
                      />
                  </div>
                
                  <div>
                    <v-text-field
                      v-model="dataFrom.cpf_cnpj"
                      v-mask="maskCpf"
                      :rules="CnpjRules"
                      color="#00796b"
                      label="CNPJ"
                      type="text"
                      placeholder="cnpj"
                      hide-details="auto"
                      prepend-inner-icon="mdi-account"
                      required
                    /><br>
                  </div>

                  <div>
                    <v-text-field
                      v-model="dataFrom.phone"
                      color="#00796b"
                      label="Celulcar"
                      type="phone"
                      v-mask="maskCelular"
                      placeholder="Celulcar"
                      prepend-inner-icon="mdi-phone"
                      required
                    />
                  </div>

                  <div>
                    <v-text-field
                      v-model="dataFrom.telephone"
                      color="#00796b"
                      label="Telefone"
                      type="text"
                      v-mask="maskTelefone"
                      placeholder="Telefone"
                      prepend-inner-icon="mdi-phone-classic"
                      required
                    />
                  </div>

                  <div>
                    <v-text-field
                      v-model="dataFrom.email"
                      :rules="emailRules"
                      color="#00796b"
                      label="Email"
                      type="email"
                      placeholder="Email"
                      prepend-inner-icon="mdi-mailbox"
                      required
                    />
                  </div>

                  <div>
                    <v-text-field
                      v-model="dataFrom.password"
                      :rules="passwordRules"
                      color="#00796b"
                      label="Password"
                      placeholder="Password"
                      prepend-inner-icon="mdi-key"
                      :append-icon="passwordShow ? 'mdi-eye' :'mdi-eye-off'"
                      :type="passwordShow?'text':'password'"
                      @click:append="passwordShow = !passwordShow"
                      required
                    />
                  </div>

                  

                  <div class="container-login100-form-btn">
                      <v-card-actions class="justify-center">
                        <v-btn x-large style="width: 200px;" :loading="loading" type="submit" color="#00796b" >
                          <span class="white--text px-8">
                            <strong>
                              Cadastrar-se
                            </strong>
                          </span>
                        </v-btn>
                      </v-card-actions>
                  </div>

                  <router-link style="font-weight: 900;" to="/login">
                        <div class="color-event">Já tem uma conta? ?  
                          <span style="text-decoration: underline;">Faça Login</span></div>
                  </router-link>
                </v-form>
              </div>
            </div>
          </div>
          <v-snackbar color="#00796b" v-model="snackbar">
            Login efetuado com sucesso
          </v-snackbar>
        </v-main>
      </v-app>
    </div>
  </template>

<script>
import InputNameEmpresa from '../components/register/Inuput_name_empresa.vue'
import InputCnpj from '../components/register/Input_cnpj.vue';
import InputCelular from '../components/register/Input_celular.vue'
import InputTelcelular from '../components/register/Input_telcelular.vue'
import InputMail from '../components/register/Input_mail.vue'
import InputPassword from '../components/register/Input_Password'
import RegisterApi from '@/services/Register/index'
import validator from 'cpf-cnpj-validator';
import Register from '@/services/Register/index';
import Alert from '@/components/alert/Alert.vue'

  const Joi = require('joi').extend(validator)
  const cnpjSchema = Joi.document().cnpj();

// @ is an alias to /src;
export default {
  name: 'Register',
  data () {
    return {
      loading:false,
      snackbar:false,
      passwordShow:false,
      dataFrom: {
        name: '',
        name_reference: '',
        cpf_cnpj: '',
        phone: '',
        telephone: '',
        email: '',
        password: ''
      },
      maskCpf: '##.###.###/####-##',
      maskCelular: '(##) #####-####',
      maskTelefone: '#### ### ####',
      nameEmpresaRules: [
        v => !!v || 'O nome é obrigatório',
        v => v.length <= 100 || 'O nome deve ter menos de 100 caracteres'],
      CnpjRules: [
        v => !!v || 'Cnpj é obrigatório',
        v =>  (v && v.replace(/[^\d]+/g,'').length >= 14) || 'Cnpj deve ser preenchido por completo',
        v =>   (cnpjSchema.validate(v.replace(/[^\d]+/g,''))['error']) != 'ValidationError: CNPJ inválido' || 'Cnpj inválido'],
      emailRules: [
        v => !!v || 'O e-mail é obrigatório',
        v => /.+@.+\..+/.test(v) || 'E-mail deve ser válido'],
      passwordRules: [
        v => !!v || 'Password é obrigatório',
        v => (v && v.length >= 8) || 'Password deve ter 8 caracteres ou mais!']
    }
  },
  mounted(){},
  methods:{
    submitHandler(){
      if (this.$refs.form.validate()){
          this.loading = true
        setTimeout(()=> {
          this.loading = false
          this.snackbar = true

          var json = {
            name: this.dataFrom.name,
            name_reference: this.dataFrom.name_reference,
            cpf_cnpj: RegisterApi.formatCnpj(this.dataFrom.cpf_cnpj),
            phone: RegisterApi.formatCelTel(this.dataFrom.phone),
            telephone: Register.formatTrim(this.dataFrom.telephone),
            email: this.dataFrom.email,
            password: this.dataFrom.password
          }
          
          RegisterApi.register(json)

        },3000)
      }
    }
  },
  components:{
    InputNameEmpresa,
    InputCnpj,
    InputCelular,
    InputTelcelular,
    InputMail,
    InputPassword,
    Alert
  }
}
</script>
<style>
.background{
    background-color: #00796b !important;
    height: 300px;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    background-size: cover;
    background: linear-gradient(-45deg, #55A38B, #00796b, #80cbc4);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.input-color{
  color: "#00796b"
}
.border-sobra{
  -webkit-box-shadow: 9px 7px 5px rgba(50, 50, 50, 0.77);
		-moz-box-shadow:    9px 7px 5px rgba(50, 50, 50, 0.77);
		box-shadow:         9px 7px 5px rgba(50, 50, 50, 0.77);
}
.color-event{
  color: #4D4D4C;
}

.color-event:hover{
  color: #000;
}
</style>